import Api from "./Api";

export const getAllVendor = async (params) =>
  Api().get(`vendor`, { params });
// export const getAllVendor1 = async (search, limit, page, deleted) => Api().get(`vendor`, { params: { limit, page, search, deleted } })

export const getVendorByID = async (id) => Api().get(`vendor/${id}`);

export const updateVendor = async (id, payload) =>
  Api().put(`vendor/${id}`, payload);

export const addVendor = async (payload) => Api().post(`vendor`, payload);

export const deleteVendor = async (payload) =>
  Api().post(`vendor/delete`, payload);

export const getVendorLogs = async (id) => Api().get(`vendor/logs/${id}`);
