import Api from "./Api";

export const getProfile = async () => Api().get("master/profile");

export const getNewNotifications = async () =>
  Api().get("master/new-notifications");

export const getAllNotifications = async (params) =>
  Api().get("master/all-notifications", { params });

export const getAllData = async (params) =>
  Api().get(`label/list/${params.master}/all`, { params });

export const getAllTreeDataActive = async (master, sub_master, limit, page) =>
  Api().get(`label/active/${master}/${sub_master}/all`, {
    params: { limit, page },
  });

export const getValueData = async (master, id, deleted = 0) =>
  Api().get(`label/val/${master}/${id}`, { params: { deleted } });

export const updateData = async (master, payload, id) =>
  Api().put(`label/${id}`, { ...payload, lbl_cat: master });

export const addData = async (master, payload) =>
  Api().post(`label`, { ...payload, lbl_cat: master });

export const saveGroup = async (payload) =>
  Api().post(`label/save-group`, payload);

export const getGroup = async (lbl_id, lbl_is_group) =>
  Api().get(`label/get-group/${lbl_id}/${lbl_is_group}`);

export const uploadImages = async (formData) =>
  Api().post(`master/uploadImages`, formData);

export const uploadFiles = async (formData, onUploadProgress) =>
  Api().post(`master/uploadFiles`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    onUploadProgress,
  });

export const deleteLabel = async (payload) =>
  Api().post("label/delete", payload);

export const getMasterList = async ({ list_route, params }) => {
  return Api().get(`master/list/${list_route}`, { params });
};

export const getHelpDescription = async (params) => {
  return Api().get(`master/help`, {
    params,
  });
};

export const saveHelpDescription = async (payload) => {
  return Api().post(`master/help`, payload);
};

export const getAllMovies = async (payload) => {
  return Api().get(`master/list/movie`, { params: payload });
};
